var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"large-page"},[_c('breadcrumbs',[_c('div',{staticClass:"d-flex flex-wrap flex-grow-1 justify-end align-center"},[_c('v-text-field',{staticClass:"mr-3 mr-md-6 input-element",attrs:{"dark":"","dense":"","outlined":"","hide-details":"","label":"Search Users","append-icon":"search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","color":"white"},on:{"click":function($event){return _vm.$router.push({ name: (_vm.prefix + "/Create") })}}},[_c('v-icon',{attrs:{"color":"secondary","left":""}},[_vm._v(" add ")]),_vm._v(" Add User ")],1)],1)]),(_vm.data)?[_c('v-data-table',{staticClass:"mt-4 box-shadow-soft",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"page":_vm.configPage,"sort-by":_vm.configSortBy,"sort-desc":_vm.configSortDesc,"items-per-page":_vm.configItemsPerPage},on:{"update:page":function (v) { return _vm.configPage = v; },"update:sort-by":function (v) { return _vm.configSortBy = v; },"update:sort-desc":function (v) { return _vm.configSortDesc = v; },"update:items-per-page":function (v) { return _vm.configItemsPerPage = v; }},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.balance / _vm.constants.conversionRate)+" ")]}},{key:"item.usage_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.usage_id / _vm.constants.conversionRate)+" ")]}},{key:"item.usage_ii",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.usage_ii / _vm.constants.conversionRate)+" ")]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(item.country)?_c('v-img',{attrs:{"height":"13","width":"20","src":_vm.proxyUrl(("https://flagcdn.com/w20/" + (item.country.toLowerCase()) + ".png"), true)}}):_vm._e()]}},{key:"item.last_active_at_raw",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",attrs:{"title":item.last_active_at}},[_vm._v(" "+_vm._s(item.last_active_at_relative)+" ")])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_active ? "Active" : "Inactive")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: (_vm.prefix + "/UsageByMonth"), params: { userId: item.id } })}}},[_vm._v(" Details ")]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: (_vm.prefix + "/Edit"), params: { userId: item.id } })}}},[_vm._v(" Edit ")])],1)]}}],null,false,3269699615)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }