<template>
  <div>
    <usage
      :data="data"
      prefix="Admin/Users"
    />

    <div
      v-if="shouldShowProgressBar"
      :title="`${progressValue} out of ${totalValue} users computed!`"
      class="progress-bar-container"
    >
      <v-progress-linear
        color="primary"
        :buffer-value="computedValue + 10"
        :value="computedValue"
        stream
      />
    </div>
  </div>
</template>

<script>
// Import the common usage table component
import Usage from "@/components/admin/common/Usage.vue"

// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"
import responseHelper from "@/helpers/responseHelper"

// The subscriptionId for message events
const subscriptionId = Symbol("Admin/User/Usage")

// Export the SFC
export default {
  // Name of the component
  name: "AdminUserUsageIndex",

  // Register the components
  components: {
    Usage
  },

  // Define local data variables
  data: () => ({
    // The current progress value
    shouldShowProgressBar: false,
    progressValue: 0,
    totalValue: 100,

    // The triggerId for the message event
    triggerId: null
  }),

  // Define local readonly variables
  computed: {
    /**
     * Get the data from store
     *
     * @returns {Null|Array}
     */
    data() {
      return this.$store.getters["admin/users/creditUsageResponse"]
    },

    /**
     * Get the current progress bar value
     *
     * @returns {Number}
     */
     computedValue() {
      return Math.floor((this.progressValue / this.totalValue) * 100)
    }
  },

  // Define local method functions
  methods: {
    /**
     *
     * @param {Object} event
     * @returns {void}
     */
    async handleMessageEvent(event) {
      // Based on the event key
      switch (event.key) {
        // If the key is "started"
        case "compute-user-credit-usage-started":
          // Set the progress value
          this.progressValue = 0

          // Show the progress bar
          this.shouldShowProgressBar = true
          break

        // If the key is "progress"
        case "compute-user-credit-usage-progress":
          // Set the progress value
          this.progressValue = event.localData.progress.completed
          this.totalValue = event.localData.progress.total

          break

        // If the key is "completed"
        case "compute-user-credit-usage-completed":
          // Set the progress value
          this.progressValue = 100

          // Hide the progress bar
          this.shouldShowProgressBar = false

          // Get the data from API
          const response = await responseHelper(event.localData.responseId)

          // If we do have some data
          if (response) {
            // Store the data in the kind of storage we want to
            this.$store.commit("admin/users/UPDATE_CREDIT_USAGE_RESPONSE", response)
          }

          break

        // If the key is "failed"
        case "compute-user-credit-usage-failed":
          // Set the progress value
          this.progressValue = 0

          // Hide the progress bar
          this.shouldShowProgressBar = false

          // Show a toast
          this.$store.dispatch("toasts/add", { text: "An error occurred!" })

          break
      }
    }
  },

  /**
   * As soon as the component has been rendered
   *
   * @returns {void}
   */
  async created() {
    // If we don't have the API data
    if (!this.data) {
      // Show a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      // Make network request
      try {
        // Set the triggerId
        this.triggerId = String(Date.now())

        // Query parameters
        const params = {
          triggerId: this.triggerId,
          clientId: getClientID()
        }

        // Register a subscriber for messageEvents
        messageEvents.register({
          id: subscriptionId,
          module: "user-credit-usage",
          type: "all",
          key: "all",
          validator: (event) => event.module === "user-credit-usage" && event.localData.clientId === getClientID(),
          callback: this.handleMessageEvent
        })

        // Use helper function
        await axios({
          url: `/api/admin/user-credits?${new URLSearchParams(params).toString()}`,
        })
      }
      // Catch any error
      catch (error) {
        // Log the error
        logger({ type: "Credit Usage Fetch Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })

        // Unregister the subscriber for messageEvents
        messageEvents.deregister(subscriptionId)
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
      }
    }
  },

  /**
   * As soon as the component has been destroyed
   *
   * @returns {void}
   */
  destroyed() {
    // Unregister the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>
