<template>
  <div class="large-page">
    <breadcrumbs>
      <div class="d-flex flex-wrap flex-grow-1 justify-end align-center">
        <!-- search input -->
        <v-text-field
          v-model="searchQuery"
          dark
          dense
          outlined
          hide-details
          label="Search Users"
          class="mr-3 mr-md-6 input-element"
          append-icon="search"
        />

        <!-- add new influencer CTA -->
        <v-btn
          depressed
          class="mr-3"
          color="white"
          @click="$router.push({ name: `${prefix}/Create` })"
        >
          <v-icon color="secondary" left>
            add
          </v-icon>

          Add User
        </v-btn>
      </div>
    </breadcrumbs>

    <template v-if="data">
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        :page="configPage"
        :sort-by="configSortBy"
        :sort-desc="configSortDesc"
        :items-per-page="configItemsPerPage"
        @update:page="(v) => configPage = v"
        @update:sort-by="(v) => configSortBy = v"
        @update:sort-desc="(v) => configSortDesc = v"
        @update:items-per-page="(v) => configItemsPerPage = v"
        class="mt-4 box-shadow-soft"
      >
        <template v-slot:item.balance="{ item }">
          {{ item.balance / constants.conversionRate }}
        </template>

        <template v-slot:item.usage_id="{ item }">
          {{ item.usage_id / constants.conversionRate }}
        </template>

        <template v-slot:item.usage_ii="{ item }">
          {{ item.usage_ii / constants.conversionRate }}
        </template>

        <template v-slot:item.country="{ item }">
          <v-img
            v-if="item.country"
            height="13"
            width="20"
            :src="proxyUrl(`https://flagcdn.com/w20/${item.country.toLowerCase()}.png`, true)"
          ></v-img>
        </template>

        <template v-slot:item.last_active_at_raw="{ item }">
          <span
            class="cursor-pointer"
            :title="item.last_active_at"
          >
            {{ item.last_active_at_relative }}
          </span>
        </template>

        <template v-slot:item.is_active="{ item }">
          {{ item.is_active ? "Active" : "Inactive" }}
        </template>

        <template v-slot:item.action="{ item }">
          <div class="d-flex">
            <v-btn
              small
              depressed
              class="mr-3"
              color="primary"
              @click="$router.push({ name: `${prefix}/UsageByMonth`, params: { userId: item.id } })"
            >
              Details
            </v-btn>

            <v-btn
              small
              depressed
              color="primary"
              @click="$router.push({ name: `${prefix}/Edit`, params: { userId: item.id } })"
            >
              Edit
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
// Import the breadcrumb component for routes
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")

// Export the SFC
export default {
  // Name of the component
  name: "Usage",

  // Register the components
  components: {
    Breadcrumbs
  },

  // Accept incoming props from parent
  props: {
    // The API data
    data: {
      type: Array,
      required: false,
      default: null
    },

    // To prefix for routes
    prefix: String,

    // Whether or not to show the ID column
    hideId: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  // Define local data variables
  data: () => ({
    searchQuery: "",

    columns: [
      {
        text: "ID",
        value: "id",
        sortable: true
      },
      {
        text: "Name",
        value: "name",
        sortable: true
      },
      {
        text: "Email ID",
        value: "email",
        sortable: true
      },
      {
        text: "Country",
        value: "country",
        sortable: true
      },
      {
        text: "Balance",
        value: "balance",
        sortable: true
      },
      {
        text: "Influencer Discovery",
        value: "usage_id",
        sortable: true
      },
      {
        text: "Influencer Insights",
        value: "usage_ii",
        sortable: true
      },
      {
        text: "Last Active At",
        value: "last_active_at_raw",
        sortable: true,
        sort: (a, b) => {
          const timestampA = a ? dayjs(a).unix() : null
          const timestampB = b ? dayjs(b).unix() : null

          // Both A and B are null
          if (timestampA === null && timestampB === null) return 0

          // Only A is null
          if (timestampA === null) return 1

          // Only B is null
          if (timestampB === null) return -1

          // Compare timestamps
          if (timestampA > timestampB) return -1
          if (timestampA < timestampB) return 1

          return 0
        }
      },
      {
        text: "Action",
        value: "action",
        sortable: false
      },
    ]
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the table headers
     *
     * @returns {Array}
     */
    tableHeaders() {
      return this.columns.filter((item) => item.value === "id" ? !this.hideId : true)
    },

    /**
     * Get the table row items
     *
     * @returns {Array}
     */
    tableItems() {
      return this.data
        ? this.data
          .filter((item) => item.name.toLowerCase().search(this.searchQuery.toLowerCase()) > -1 || item.email.toLowerCase().search(this.searchQuery.toLowerCase()) > -1)
          .map((item) => ({
            ...item,
            usage_id: item.usage.debits[this.constants.model.transaction.for.influencerDiscovery],
            usage_ii: item.usage.debits[this.constants.model.transaction.for.influencerInsight],
            last_active_at: item.last_active_at ? dayjs.utc(item.last_active_at).tz(dayjs.tz.guess()).format("LLL") : 'NA',
            last_active_at_relative: item.last_active_at ? dayjs().to(dayjs.utc(item.last_active_at)) : 'NA',
            last_active_at_raw: item.last_active_at
        }))
        : []
    },

    /**
     * Get the pagination page number from store
     */
    configPage: {
      get() {
        return this.$store.getters["config/get"]("usage-pagination-page")
      },

      set(value) {
        this.$store.dispatch("config/set", {
          key: "usage-pagination-page",
          value
        })
      }
    },

    /**
     * Get the pagination items per page from store
     */
    configItemsPerPage: {
      get() {
        return this.$store.getters["config/get"]("usage-pagination-items-per-page")
      },

      set(value) {
        this.$store.dispatch("config/set", {
          key: "usage-pagination-items-per-page",
          value
        })
      }
    },

    /**
     * Get the pagination sort by from store
     */
    configSortBy: {
      get() {
        return this.$store.getters["config/get"]("usage-pagination-sort-by")
      },

      set(value) {
        this.$store.dispatch("config/set", {
          key: "usage-pagination-sort-by",
          value
        })
      }
    },

    /**
     * Get the pagination sort desc from store
     */
    configSortDesc: {
      get() {
        return this.$store.getters["config/get"]("usage-pagination-sort-desc")
      },

      set(value) {
        this.$store.dispatch("config/set", {
          key: "usage-pagination-sort-desc",
          value
        })
      }
    }
  },

  /**
   * As soon as the component is created
   *
   * @returns {void}
   */
  created() {
    // Check if we don't have the configPage value
    if (!this.configPage) {
      // Set the configPage value to 1
      this.configPage = 1
    }

    // Check if we don't have the configItemsPerPage value
    if (!this.configItemsPerPage) {
      // Set the configItemsPerPage value to 10
      this.configItemsPerPage = 10
    }
  }
}
</script>

<style lang="stylus" scoped>
.input-element
  max-width 20em
</style>
