import { render, staticRenderFns } from "./Usage.vue?vue&type=template&id=8a624332&scoped=true"
import script from "./Usage.vue?vue&type=script&lang=js"
export * from "./Usage.vue?vue&type=script&lang=js"
import style0 from "./Usage.vue?vue&type=style&index=0&id=8a624332&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a624332",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDataTable,VIcon,VImg,VTextField})
